.social-media-approver-container {
	
	.flex-grid {
		display: grid;  /* Changed to grid for better height matching */
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
		gap: 20px;
		padding: 20px;
	}
	
	.grid-item {
		min-width: 0; /* Prevent overflow */
	}
	
	.post-header {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
	}
	
	.post-header > div {
		flex: 1 1 auto;
	}
	
	.post-header .full-width {
		flex: 1 1 100%;
	}
	
	.media-container {
		flex: 1;
		min-height: 0;
		position: relative;
	}
	
	.media-container img,
	.media-container video {
		width: 100%;
		height: auto;
		max-height: 100%;
		object-fit: contain;
	}
	
	.caption {
		margin: 15px 0;
	}
	
	.button-container {
		display: flex;
		gap: 10px;
		margin-top: auto;
	}
	
	.button-container .btn {
		flex: 1;
	}
	
	.panel {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	
	.panel-body {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
}