@import (reference) '../node_modules/bootstrap/less/bootstrap.less';

@import "../node_modules/bootstrap/less/variables.less";
@import "../node_modules/bootstrap/less/mixins/buttons.less";

@import "style/button-discreet.less";
@import "style/ng-strap.less";
@import "style/medialabel.less";
@import "style/schedule.less";
@import "style/social-media-approver.less";

@import "player.less";

@menu-width: 200px;
@menu-transition-time: 0.400s;
@menu-transition-mode: ease;

@gray-lightest: lighten(@gray-base, 97.25%);
@border-color: darken(@gray-lightest, 6.5%);

body {
	font-family: 'Roboto', sans-serif;
	background-color: #e0e0e0 !important; /* Light grey background */
	color: #333 !important; /* Dark grey text */
  }

  .h4 {
	font-weight:bold;
  }
  
  .panel {
	border-radius: 4px !important;
	// box-shadow: 0 2px 4px rgba(0,0,0,0.1) !important;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
	background-color: #ffffff !important; /* White panel backgrounds */
	border: none !important;
	padding: 5px !important;
  }
  
  .btn-default {
	background-color: white !important; /* Vibrant orange for buttons */
	color: #333 !important;
	border-color: #ccc !important; /* Border color to match the background */
	border-radius: 4px !important;
  }

  .btn-default:hover {
	background-color: #ebebeb !important; ;
	color: #333 !important; ;
  }

  .btn-primary {
	background-color: #f57c00 !important; /* Vibrant orange for buttons */
	color: white !important;
	border-color: #f57c00 !important; /* Border color to match the background */
	border-radius: 4px !important;
  }

  .panel {
	border-color: transparent; /* Remove default panel border */
	box-shadow: none; /* Remove default box shadow */
  }
  
  /* Style the panel heading */
  .panel-heading {
	background-color: transparent !important; /* Remove background color */
	border-bottom: 3px solid #f8ca44 !important; /* Orange bottom border for separation */
	color: #333; /* Set text color */
	margin-bottom: 10px !important;
  }
  
  /* Ensure the panel body stands out against the background */
  .panel-body {
	background-color: #ffffff; /* White background for the body */
  }

/* General navbar styling */
.navbar-default {
	background-color: #ffffff; /* White background for a cleaner look */
	border-color: #f57c00; /* Orange border for a subtle hint of the primary color */
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  
  /* Navbar brand and link styling */
  .navbar-default .navbar-brand,
  .navbar-default .navbar-nav > li > a {
	color: #333; /* Dark grey for text, ensuring readability */
  }
  
  /* Navbar link hover and focus styles */
  .navbar-default .navbar-nav > li > a:hover,
  .navbar-default .navbar-nav > li > a:focus {
	color: #f57c00; /* Orange text on hover for a pop of color */
	background-color: transparent; /* Maintain a transparent background on hover */
  }
  
  /* Active link styling */
  .navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:hover,
  .navbar-default .navbar-nav > .active > a:focus {
	color: #ffffff; /* White text for active link */
	background-color: #f57c00; /* Orange background for the active item */
  }
  
  /* Responsive toggle button color */
  .navbar-default .navbar-toggle {
	border-color: #f57c00; /* Orange border for the toggle */
  }
  
  .navbar-default .navbar-toggle .icon-bar {
	background-color: #333; /* Dark grey icon to stand out against the white navbar */
  }
  
  /* Dropdown menu styling */
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
	color: #333; /* Dark grey for dropdown links for readability */
  }
  
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
	background-color: #f57c00; /* Orange background for dropdown items on hover/focus */
	color: white; /* White text for contrast */
  }
  






.panel {
	box-shadow: 2px 7px 5px rgba(0, 0, 0, 0.30);
}

.panel-default>.panel-heading {
	color: #333333;
	background-color: #dbdbdb;
	border-color: #b3b1b1;
}

.notify-center {
	color: @gray-light;
	text-align: center;
}

.list-striped {
	margin-bottom: 0;

	>* {
		border-bottom: 1px solid @table-border-color;

		&:hover {
			background-color: @table-bg-hover;
		}

		&:nth-child(even) {
			background-color: @table-bg-accent;
		}

		&:last-child {
			border: 0;
		}
	}
}

.scene-content {
	>li {
		padding: 10px;
		min-height: 50px;
	}

	.interval {
		border: dashed 1px @border-color;
		padding: 10px;
	}
}

.scene-content-duration {
	display: inline;
	width: 120px;
}

.scene-content-duration-btns {
	display: inline;
}

.ib {
	display: inline-block;
}

.form-sub {
	margin-top: 10px;
}

// Library stuff

.ellipsis-container {
	display: inline-block;
	max-width: 335px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.center-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-media-library {
	display: flex;
	flex-wrap: wrap;
}


.flex-media-item {
	display: flex;
	flex-direction: column;
	min-width: 345px;
	max-width: 345px;
	padding: 5px;
	margin: 2px;
	border-radius: 4px;
	background-color: @table-bg-accent;
	border: 1px solid #f57c00;

	&:hover {
		background-color: @table-bg-hover;
	}

	.header {}

	.content {
		display: flex;

		.thumb {
			height: 100px;
			width: 100px;

			border-radius: 5px;
			border: 1px solid @table-border-color;

			margin-right: 5px;
		}

		.controls {
			display: flex;
			flex-direction: column;
			flex: 1;

			>* {
				flex: 1;
				margin-bottom: 2px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&.create {
		min-height: 100px;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	asset-upload-drop-zone& {
		min-height: 100px;
		border: 2px dashed @border-color;
		transition: all 500ms @menu-transition-mode;

		.asset-upload-drag-over {
			color: @state-success-text;
		}

		.asset-upload-title {
			.h4;
			.text-center;
		}

		.asset-upload-files-remaining,
		.asset-upload-bytes-remaining {
			.text-center;
		}
	}
}

.btn-discreet-danger {
	.button-discreet-light(#333, #FFF, #CCC, @btn-danger-color, @btn-danger-bg, @btn-danger-border);
}

.btn-interval-now {
	.button-variant(#FFF; #00b500; darken(#00b500, 5%));
}

.btn-interval-future {
	.button-variant(#FFF; #97db97; darken(#97db97, 5%));
}

.btn-interval-past {
	.button-variant(#FFF; #f99090; darken(#f99090, 5%));
}

back-button {
	margin-right: 5px;
}

textarea {
	resize: vertical;
}


.modal-video-container {
	max-width: 100%;
	max-height: calc(100vh - 200px);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-video-container video {
	max-width: 100%;
	max-height: 100%;
}

.scene-content-item.placeholder {
	/* styles for the placeholder */
	background-color: #b9d4ab;
	border: 2px dashed #888;
	min-height: 20px;
}

.video4k-label {
	position: absolute;
	padding: 2px;
	color: #fff;
	background: rgba(0, 0, 0, 0.5);
	font-size: 9px;
	z-index: 10;
}

.player-log-table tr.highlight td {
	background-color: #f57c00;
	color: #fff;
}

.player-log-table tr.removed td {
	text-decoration: line-through;
}

.truncate {
	width: 250px; /* Define the width of the container */
	white-space: nowrap; /* Prevent text from wrapping to a new line */
	overflow: hidden; /* Hide text that exceeds the container */
	text-overflow: ellipsis; /* Add an ellipsis at the cut-off point */
  }
